import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { Shell } from "./shell/services/shell.service";

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule)
    }
  ])
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes, 
        { 
          preloadingStrategy: PreloadAllModules,
          anchorScrolling: 'enabled',
          useHash: true
        }
      )
    ],
    exports: [RouterModule],
    providers: []
  })
export class RoutingModule { }